<template>
  <Modal v-model="showUpdateModal" width="800">
    <div class="workplatform-title">当前基础信息</div>
    <Row>
      <i-col span="24"><span class="title">供应商 </span>{{targetDetail.supplierCompanyName}}</i-col>
      <i-col span="8"><span class="title">资源编号 </span>{{targetDetail.resourceCode}}</i-col>
      <i-col span="8"><span class="title">资产 </span>{{targetDetail.assetName}}</i-col>
      <i-col span="8"><span class="title">位置 </span>{{targetDetail.stationName}}</i-col>
      <i-col span="8"><span class="title">作业类型 </span>{{targetDetail.taskTypeName}}</i-col>
      <i-col span="8"><span class="title">资源类型 </span>{{targetDetail.resourceTypeName}}</i-col>
      <i-col span="8"><span class="title">费用类型 </span>{{targetDetail.costTypeName||'-'}}</i-col>
      <i-col span="8"><span class="title">计费数量 </span>{{targetDetail.chargeQuantity +targetDetail.chargeQuantityUnit}}</i-col>
      <i-col span="8"><span class="title">计费单价 </span>{{targetDetail.formatUnitCost+'/'+targetDetail.chargeQuantityUnit}}</i-col>
    </Row>
    <!-- <div class="p-t-10 workplatform-title" v-if="targetDetail.status!==2">可调整计费项</div>
    <Row v-if="targetDetail.status!==2" :gutter="8">
      <i-col span="8"><span class="title">计费单位</span>
        <Select v-model="query.chargeType"
            size="small">
          <Option v-for="(unit,index) in unitArray" :key="index" :value="unit.id">{{unit.name}}</Option>
        </Select>

      </i-col>
      <i-col span="8"><span class="title">计费数量</span>  <InputNumber
            v-model="query.chargeQuantity"
            style="width:100%"
            size="small"
            placeholder="请填写调整项金额"
          ></InputNumber></i-col>
      <i-col span="8"><span class="title">计费单价(¥)</span>  <InputNumber
            v-model="query.unitCost"
            style="width:100%"
            size="small"
            placeholder="请填写调整项金额"
          ></InputNumber></i-col>
    </Row> -->
    <div>
      <div class="p-t-10 workplatform-title">计费项变更历史</div>
       <Table
         stripe border :data="historylist" :columns="historyColumn"></Table>
    </div>

    <div slot="footer">
        <Button type="text"  @click="showUpdateModal=false">取消</Button>
        <Button type="primary"  @click="confirmUpdate">确认</Button>
      </div>
  </Modal>
</template>
<script>
import { toMoney } from '@/utils/wnumb_own'
import { modifycharge } from '@/api/msp/settlement'
export default {
  data () {
    return {
      unitArray: [
        {
          id: 0,
          name: '次'
        },
        {
          id: 1,
          name: '块'

        }, {
          id: 2,
          name: '㎡'
        }],
      query: {
        chargeQuantity: 1,
        chargeType: 1,
        unitCost: 0
      },

      showUpdateModal: false,
      targetDetail: {},
      historylist: [],
      historyColumn: [
        {
          title: '计费数量',
          key: 'companyName',
          render: (h, params) => {
            return h('div', [h('p', params.row.chargeQuantity + '' + params.row.chargeQuantityUnit)])
          }
        },

        {
          title: '计费单价',
          key: 'companyName',
          render: (h, params) => {
            return h('div', [h('p', toMoney(params.row.chargeUnitCost) + '/' + params.row.chargeQuantityUnit)])
          }
        },
        {
          title: '计费总价',
          key: 'companyName',
          render: (h, params) => {
            return h('div', [h('p', toMoney(params.row.cost))])
          }
        },
        {
          title: '变更时间',
          key: 'companyName',
          render: (h, params) => {
            return h('div', [h('p', params.row.createTime)])
          }
        },
        {
          title: '变更操作人',
          key: 'companyName',
          render: (h, params) => {
            return h('div', [h('p', params.row.userName)])
          }
        }
      ]
    }
  },
  methods: {
    showModal (item) {
      this.targetDetail = item
      this.historylist = item.chargeHistoryList
      this.query.chargeQuantity = item.chargeQuantity
      this.query.chargeType = item.chargeType
      this.query.unitCost = item.unitCost
      this.showUpdateModal = true
    },
    // 确认修改
    confirmUpdate () {
      const that = this
      this.query.taskitemChargeId = this.targetDetail.id
      modifycharge(that.query).then(res => {
        if (res && !res.errcode) {
          that.showUpdateModal = false
          this.$Notice.success({ desc: '成功调整计费项！' })
          that.$store.commit('set_reconciliationDemo_update', new Date())
        }
      })
    }
  }
}
</script>
